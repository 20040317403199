<template>
    <div style="width:100vw;margin:-12px;position:relative;" class="fill-height">
        <!-- app bar -->
        <top-nav title="Listado de sesiones"></top-nav>
        
        <div v-if="sessions != null" class="mt-5">
            <v-text-field v-model="q" class="rounded-xl mb-3 mx-3 mt-2" flat solo background-color="grey lighten-3" label="Buscar" hide-details dense prepend-inner-icon="mdi-magnify" clearable></v-text-field>
            <div v-if="q && filteredSessions.length === 0" class="d-flex justify-center align-center px-3" style="width: 100%;line-break:anywhere;">
                <v-icon class="mr-2">mdi-alert-circle-outline</v-icon>
                <span>No se encontraron resultados para <strong>"{{ q }}"</strong></span>
            </div>
            <div v-else-if="q" class="d-flex justify-center align-center px-3" style="width: 100%;line-break:anywhere;">
                <span>Mostrando {{ filteredSessions.length }} {{ resultText }} para <strong>"{{ q }}"</strong></span>
            </div>
            <!-- content -->
            <v-list two-line>
                <template v-for="(session, ix) in filteredSessions">
                    <v-list-item :key="session.id" :to="'/secure/session/' + session.id">
                        <v-list-item-content>
                            <v-list-item-title class="text-left">{{ session.name }}</v-list-item-title>
                            <v-list-item-subtitle class="text-left">
                                Creada: {{ formatDate( session.dateCreated ) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-chip size="16">
                                {{ session.checkIn.length }}
                            </v-chip>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider inset v-if="ix < sessions.length - 1" :key="session.id + '-divider'"></v-divider>
                </template>
            </v-list>
            <v-btn color="primary" dark fixed bottom right fab class="addBtn" to="/secure/session">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import { PetuSession } from '@/classes/session';
import { _st } from '@/softech';
import TopNav from '@/components/admin/TopNav';
import moment from 'moment';

export default {
    data: () => ({ 
        q           : '', 
        sessions    : [] 
    }),
    computed: {
        filteredSessions() {
            let q = this.q?.toLowerCase() || null;
            
            return !this.q 
                ? this.sessions
                : this.sessions.filter((s) => {
                    return s.name.toLowerCase().includes(q) || s.dateCreated.toLowerCase().includes(q)
                });
        },
        resultText() {
            return this.filteredSessions.length === 1 ? 'resultado' : 'resultados'
        },
    },
    async mounted() {
        let session = new PetuSession();
        let sessions = await session.getList({ 
            from: moment().subtract( 14, 'days' ).format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD')
        });

        // sort sessions by date
        this.sessions = sessions.sort((a,b) => (a.dateCreated <= b.dateCreated) ? 1 : ((b.dateCreated <= a.dateCreated) ? -1 : 0));
    },
    methods: {
        formatDate( d ) {
            return moment(d).format('YYYY-MM-DD @ hh:mm a')
        }
    },
    components: {
        TopNav
    }
}
</script>

<style lang="scss" scoped>
.addBtn {
    bottom: 15px !important;
}
</style>